import React, { useEffect, useState, useRef } from "react";
import offerCard from "../../images/offerCard.png";
import scissor from "../../images/scissorTwo.svg";
import CouponModal from "../../components/modal/CouponModal";
import { useDispatch, useSelector } from "react-redux";
import {
  DawonloadCoupen,
  GetUserCoupens,
} from "../../ReduxToolkit/Slices/Deals/Deals.action";
import { BaseUrl } from "../../ReduxToolkit/Apis";
import moment from "moment";
import GenerateCouponPdf from "../../components/GanerateCoupenPdf";
import { useLocation } from "react-router-dom";
import { GetUserProfileDetails } from "../../ReduxToolkit/Slices/Auth/Auth.action";
import toast from "react-hot-toast";

const MyCoupons = () => {
  const [isCouponOpen, setIsCouponOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null); // State for selected coupon
  const closeCouponModal = () => setIsCouponOpen(false);
  const location = useLocation();
  let dispatch = useDispatch();
  let localdata = JSON.parse(localStorage.getItem("SaveOn_Web_Cread"));
  console.log(localdata, "localdata");

  let [coupensData, setCoupensData] = useState({});
  useEffect(() => {
    dispatch(
      GetUserCoupens({ apikey: localdata?.apikey, token: localdata?.token })
    )
      .unwrap()
      .then((result) => {
        console.log(result, "Result Data");
      });
    dispatch(GetUserProfileDetails(localdata?.id));
  }, [location]);

  let DealsDetails = useSelector((store) => store?.Deals);

  const handleDownload = (coupon) => {
    setSelectedCoupon({
      offerData: {
        strike_amount: coupon.strike_amount,
        real_amount: coupon.real_amount,
        title: coupon.name,
      },
      coupendata: {
        code: coupon.code,
        expiry_date: coupon.expiry_date,
      },
      offerCard: coupon.image?.image
        ? `${BaseUrl}/${coupon.image.image}`
        : "https://via.placeholder.com/150",
    });
  };

  let [isDawonloadLoading, setisDawonloadLoading] = useState(false);
  let [SelectedCoupen, setSelectedCoupen] = useState(null);

  let DawonloadThisCoupen = async (coupensdata) => {
    let coupenData = new FormData();
    coupenData.append("voucher_id", coupensdata?.voucher_id);
    setisDawonloadLoading(true);
    await dispatch(DawonloadCoupen(coupenData))
      .unwrap()
      .then((result) => {
        if (result?.s == 1) {
          const pdfUrl = result?.r?.pdf_url;
          if (pdfUrl) {
            window.open(pdfUrl, "_blank");
          } else {
            toast.error(result?.m);
          }
          setisDawonloadLoading(false);
        } else {
          toast.error(result?.m);
          setisDawonloadLoading(false);
        }
      });
  };
  return (
    <div className="flex md:gap-y-10 lg:gap-y-14 m-auto items-center md:items-start justify-center md:justify-start flex-wrap gap-6">
      {selectedCoupon && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="w-[90%] justify-end h-[90%] bg-white rounded-lg p-4">
            <button
              className="bg-primary-blue text-end mb-2 p-2 text-white "
              onClick={() => setSelectedCoupon(null)}
            >
              Close
            </button>
            <GenerateCouponPdf data={selectedCoupon} />
          </div>
        </div>
      )}
      {isCouponOpen && <CouponModal closeCouponModal={closeCouponModal} />}
      {DealsDetails?.UserCoupens?.length === 0 ||
      DealsDetails?.UserCoupens == null ? (
        <div className="flex items-center justify-center w-full text-lg font-semibold">
          No Coupons!
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {DealsDetails?.UserCoupens?.map((coupon) => {
            return (
              <div key={coupon?.id} className="w-fit relative">
                <div className="absolute flex flex-col justify-between h-full z-10 -left-2">
                  {Array.from({ length: 12 }).map((_, i) => (
                    <div key={i} className="p-2 rounded-full bg-[#FAFAFA]" />
                  ))}
                </div>
                <div className="absolute flex flex-col justify-between h-full z-10 -right-2">
                  {Array.from({ length: 12 }).map((_, i) => (
                    <div key={i} className="p-2 rounded-full bg-[#FAFAFA]" />
                  ))}
                </div>
                <div className="flex w-[100%]">
                  <div className="w-[100%] md:w-[100%] h-[140px] md:h-[176px] ">
                    <img
                      width={"100%"}
                      className=" h-[inherit]"
                      src={`${BaseUrl}/${coupon?.image?.image}`}
                      alt="offer"
                    />
                  </div>
                  <div className="p-2 md:p-6 w-[60%] md:w-[60%] flex flex-col gap-1 justify-start items-start bg-light-blue">
                    <div className="flex justify-start items-baseline gap-1 py-1 px-6 w-fit rounded-md bg-dark-blue">
                      <span className="text-text text-white font-semibold text-lg md:text-xl">
                        ${coupon?.strike_amount}
                      </span>
                      <span className="text-white text-sm font-light line-through">
                        ${coupon?.real_amount}
                      </span>
                    </div>
                    <p className="text-dark-blue text-md md:text-xl font-semibold">
                      {coupon?.name}
                    </p>
                    <p className="text-dark-blue flex  text-md md:text-[16px]   p-1 bg-primary-blue text-white font-semibold">
                      {coupon?.code}
                    </p>
                    <p className="text-[rgba(19,87,121,0.7)] text-sm md:text-[13px] font-medium">
                      <span className=" ">Exp : </span>
                      {coupon?.expiry_date == null
                        ? "----"
                        : moment(coupon?.expiry_date)
                            .local()
                            .format("DD MMM YYYY") || "--"}
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => {
                    DawonloadThisCoupen(coupon);
                    setSelectedCoupen(coupon);
                    // handleDownload(coupon);
                  }}
                  className="relative w-full py-2 lg:py-4 bg-dark-blue text-white text-xl md:text-2xl font-semibold border-t-2 border-white border-dashed"
                >
                  {SelectedCoupen?.voucher_id == coupon?.voucher_id &&
                  isDawonloadLoading
                    ? "Dawonloding..."
                    : "Download"}
                  {/* Download */}
                  <img
                    className="absolute -top-[13px] z-30 left-10"
                    src={scissor}
                    alt="scissor"
                  />
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MyCoupons;
