import React from "react";
import Marquee from "react-fast-marquee";
import amazonLogo from "../images/amazon.png";
import flipkartLogo from "../images/flipkart.png";
import swiggyLogo from "../images/swiggy.png";
import { BaseUrl } from "../ReduxToolkit/Apis";
import { LazyLoadImage } from "react-lazy-load-image-component";
import usericonplaceholder from "../images/usericon.png";
import "react-lazy-load-image-component/src/effects/blur.css";
const Marque = ({ data, loading }) => {
  return (
    <Marquee gradient direction={"left"} className="flex items-center ">
      {data
        ?.filter((el) => el?.partner_logo)
        .map((partner, i) => (
          <div
            key={i}
            className="border-[#6E818D24] h-[90px] xl:h-[120px] mx-2 md:mx-4 border bg-[#FAFAFA] flex items-center justify-center rounded-[5px]"
          >
            <LazyLoadImage
              alt="amazonLogo"
              placeholderSrc={usericonplaceholder}
              height={"100%"}
              width={"100%"}
              effect="blur"
              className="w-[140px] h-full rounded-[5px] md:w-[150px] xl:w-[200px]  "
              src={`${BaseUrl}${
                partner?.partner_logo ? partner?.partner_logo : ""
              }`}
            />
          </div>
        ))}
    </Marquee>
  );
};

export default Marque;
