import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { DeleteAccount } from "../../ReduxToolkit/Slices/Auth/Auth.action";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const DeleteAccWarningModal = ({ setOpenModal, OpenModal, userid }) => {
  const modalRef = useRef(null);
  let dispatch = useDispatch()

  //   useEffect(() => {
  //     const handleOutsideClick = (event) => {
  //       if (modalRef.current && !modalRef.current.contains(event.target)) {
  //         setOpenModal(false);
  //       }
  //     };

  //     document.addEventListener("mousedown", handleOutsideClick);
  //     const timer = setTimeout(() => {
  //       setOpenModal(false);
  //     }, 5000);

  //     return () => {
  //       document.removeEventListener("mousedown", handleOutsideClick);
  //       clearTimeout(timer);
  //     };
  //   }, [OpenModal, setOpenModal]);

  let [deleteLoading, setdeleteLoading] = useState(false);
  let navigate = useNavigate();
  if (!OpenModal) {
    return null;
  }
  let closethismodal = () => {
    setOpenModal(false);
  };
  let DeleteThisAcount = async () => {
    let userdata = new FormData();
    userdata.append("user_id", userid);
    setdeleteLoading(true);
    await dispatch(DeleteAccount(userdata))
      .unwrap()
      .then((result) => {
        if (result?.s == 1) {
          localStorage.removeItem("SaveOn_Web_Cread");
          setTimeout(() => {
            toast.success(result?.m);
            navigate("/");
            setOpenModal(false);
            setdeleteLoading(false);
          }, 2000);
        } else {
          toast.error("Failed to delete account");
          setOpenModal(false);
        }
      });
  };
  return (
    <div className="fixed z-[105] inset-0 bg-[rgba(54,0,17,0.84)] flex items-center justify-center">
      <div
        ref={modalRef}
        className="bg-white rounded-[32px] p-10 relative w-[90%] sm:w-[500px] tracking-wide"
      >
        <h1 className="text-lg font-semibold text-center mb-4">
          Are you sure you want to delete your account ?{" "}
        </h1>
        <div className="flex justify-center gap-6">
          <button
            className="bg-dark-blue mt-4 py-3 px-8 rounded-lg w-fit text-white transition-all duration-300 ease-in-out hover:bg-primary-blue hover:scale-105"
            onClick={DeleteThisAcount}
          >
            {deleteLoading ? "Deleting.." : "Yes"}
          </button>
          <button
            className="bg-dark-blue mt-4 py-3 px-8 rounded-lg w-fit text-white transition-all duration-300 ease-in-out hover:bg-primary-blue hover:scale-105"
            onClick={closethismodal}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccWarningModal;
