import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import showPass from "../../images/eye.svg";
import hidePass from "../../images/eyeTwo.svg";
import tag from "../../images/tag.svg";
import { useDispatch, useSelector } from "react-redux";
import { Login } from "../../ReduxToolkit/Slices/Auth/Auth.action";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { RxCrossCircled } from "react-icons/rx";

const LoginModal = ({
  closeLoginModal,
  switchToSignup,
  switchToForgotPass,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [rememberedEmail, setRememberedEmail] = useState("");
  const modalRef = useRef(null);
  let dispatch = useDispatch();
  let Auth = useSelector((store) => store?.Auth);
  let [authload, setauthload] = useState(false);
  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: rememberedEmail || "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required !"),
      password: Yup.string()
        .required("Password is required !")
        .min(8, "Password should contain at least 8 characters")
        .max(20, "Password must contain at most 20 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(
          /[\W_]/,
          "Password must contain at least one special character (e.g., !, @, #, $, etc.)"
        ),
    }),
    onSubmit: (values) => {
      let logindata = new FormData();
      logindata.append("email", values?.email);
      logindata.append("password", values?.password);
      setauthload(true);
      dispatch(Login(logindata))
        .unwrap()
        .then((result) => {
          if (result?.s == 1 && result?.r?.token) {
            toast(`${result?.m}`, {
              icon: "👏",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
                textAlign: "center",
                background: "rgba(255,48,147,1)",
              },
            });

            localStorage.setItem("SaveOn_Web_Cread", JSON.stringify(result?.r));
            setTimeout(() => {
              setauthload(false);
              //  window?.location.reload()
              navigate("/");
            }, 2000);

            if (rememberMe) {
              localStorage.setItem("rememberedEmail", values.email);
            } else {
              localStorage.removeItem("rememberedEmail");
            }

            closeLoginModal();
          } else {
            setauthload(false);
            toast.error(
              result?.m || "Something wents wrong please try again.",
              {
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              }
            );
          }
        });
    },
  });
  const handleshowPass = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeLoginModal();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeLoginModal]);

  useEffect(() => {
    const savedEmail = localStorage.getItem("rememberedEmail");
    if (savedEmail) {
      setRememberedEmail(savedEmail);
      setRememberMe(true);
    }
  }, []);

  return (
    <div className="fixed z-50 inset-0 bg-[rgba(54,0,17,0.84)] flex items-center justify-center w-full">
      <div
        ref={modalRef}
        className="bg-white rounded-[32px] p-5 md:p-8 relative w-[90%] sm:w-[500px] tracking-wide border-dashed border-2 border-[rgba(14,47,67,0.5)] shadow-[10px_8px_0_0_rgba(255,255,255,0.42)]"
      >
        <div className="text-xl sm:text-2xl text-white relative -top-16 sm:-top-20 flex items-center justify-center gap-1 text-nowrap">
          <div className="flex gap-2">
            <span className="mr-2"> </span> Login to get exclusive offers{" "}
            <img src={tag} width={25} alt="tag" />
          </div>
        </div>
        <div className="flex justify-end absolute right-8 top-6 ">
          <RxCrossCircled
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={closeLoginModal}
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-6 relative">
            <label htmlFor="email" className="text-left text-dark-text mb-2">
              Email Address
            </label>
            <input
              className="border border-gray-300 rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
              type="email"
              name="email"
              id="email"
              placeholder="Enter email address"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                background: "rgba(254,241,248,1)",
                color: "rgba(209, 5, 87, 1)",
              }}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm">{formik.errors.email}</div>
            ) : null}
          </div>

          <div className="mt-6 relative">
            <label htmlFor="password" className="text-left text-dark-text mb-2">
              Password
            </label>
            <input
              className="border border-gray-300 rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              placeholder="Enter your password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                background: "rgba(254,241,248,1)",
                color: "rgba(209, 5, 87, 1)",
              }}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="text-red-500 text-sm">
                {formik.errors.password}
              </div>
            ) : null}
            <img
              onClick={handleshowPass}
              className="absolute top-[45px] right-4 cursor-pointer"
              src={showPassword ? hidePass : showPass}
              alt="show password icon"
            />
          </div>

          <div className="mt-4 flex justify-between">
            <div className="flex items-center gap-2">
              <input
                className="form-checkbox h-4 w-4 border-2 border-primary-blue focus:border-primary-dark-blue cursor-pointer"
                type="checkbox"
                name="rememberMe"
                id="rememberMe"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                style={{
                  // background: "rgba(254,241,248,1)" ,
                  color: "rgba(255,48,147,1)",
                }}
              />

              <label
                className="text-dark-text text-sm font-semibold cursor-pointer select-none"
                htmlFor="rememberMe"
              >
                Remember Me
              </label>
            </div>
            <div>
              <p
                onClick={switchToForgotPass}
                className="underline text-dark-red-bg text-sm font-semibold select-none cursor-pointer"
              >
                Forgot Password?
              </p>
            </div>
          </div>

          <div className="mt-4 flex justify-between items-center">
            <div>
              <p
                className="text-dark-text font-semibold"
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                Don’t have account? <br className="block sm:hidden" />
                <span
                  onClick={switchToSignup}
                  className="text-primary-blue  cursor-pointer"
                >
                  {" "}
                  Sign Up
                </span>
              </p>
            </div>
            <div>
              <button
                disabled={authload}
                className="bg-dark-red-bg py-2 px-6 text-[#FFF] rounded-lg"
                type="submit"
              >
                {authload ? "Loading..." : "Login"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginModal;
