import { createSlice } from "@reduxjs/toolkit";
import { ChangePassword, ContactToUs, DeleteAccount, GetRegionList, GetUserProfileDetails, Login, Logout, ResetPassword, Signup } from "./Auth.action";

let initialState = {
  loading: false,
  error: null,
  Authdata: {},
  RegionList: [],
  UserDetailsByid:{}
};
const AuthSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    // --------- Login ---------
    builder
      .addCase(Login.pending, (state) => {
        state.loading = true;
      })
      .addCase(Login.fulfilled, (state, action) => {
        state.loading = false;
        state.Authdata = action?.payload?.r;
      })
      .addCase(Login.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Forgot Password  ---------
    builder
      .addCase(ResetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(ResetPassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(ResetPassword.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Change Password  ---------
    builder
      .addCase(ChangePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(ChangePassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(ChangePassword.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Signup ---------
    builder
      .addCase(Signup.pending, (state) => {
        state.loading = true;
      })
      .addCase(Signup.fulfilled, (state, action) => {
        state.loading = false;
        // state.Authdata = action?.payload?.r;
      })
      .addCase(Signup.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Logout ---------
    builder
      .addCase(Logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(Logout.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(Logout.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Logout ---------
    builder
      .addCase(ContactToUs.pending, (state) => {
        state.loading = true;
      })
      .addCase(ContactToUs.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(ContactToUs.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

      // ----- Get Region List ------------
    builder
      .addCase(GetRegionList.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetRegionList.fulfilled, (state, action) => {
        state.loading = false;
        state.RegionList = action.payload?.r;
      })
      .addCase(GetRegionList.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });

      // ----- Get User by Id ------------
    builder
      .addCase(GetUserProfileDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetUserProfileDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.UserDetailsByid = action.payload?.r;
      })
      .addCase(GetUserProfileDetails.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
  

      // ----- Get User by Id ------------
    builder
      .addCase(DeleteAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.UserDetailsByid ={};
        state.Authdata ={};
      })
      .addCase(DeleteAccount.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
  
  },
});

export default AuthSlice.reducer;
