import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetOneOffplans } from "../../ReduxToolkit/Slices/Deals/Deals.action";
import ViewmoreModal from "../../components/modal/ViewmoreModal";
import moment from "moment";
const OneOffPlans = () => {
  const [showViewMore, setShowViewMore] = useState(false);
  const closeViewMore = () => setShowViewMore(false);
  let dispatch = useDispatch();
  let localdata=JSON.parse(localStorage.getItem("SaveOn_Web_Cread"))

  useEffect(() => {
    dispatch(GetOneOffplans({apikey:localdata?.apikey,token:localdata?.token}));
  }, []);
  let DealsDetails = useSelector((store) => store?.Deals);
  let [viewMoreData, setviewMoreData] = useState(null);
  let ViewMoreDetails = (data) => {
    setviewMoreData(data);
    setShowViewMore(true);
  };


  return (
    <>
    {DealsDetails?.UserOneOfPlans?.length === 0?    <div className="flex items-center justify-center w-full text-lg font-semibold">
       No Plans  !{" "}
        </div>:<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className=" text-dark-text capitalize bg-transparent">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-base  text-center  sm:text-lg font-medium text-nowrap whitespace-nowrap"
              >
                Plans
              </th>
              <th
                scope="col"
                className="px-6 py-3  text-center  text-base sm:text-lg font-medium text-nowrap whitespace-nowrap"
              >
                Start Date-Time
              </th>
              {/* <th
                scope="col"
                className="px-6 py-3  text-center  text-base sm:text-lg font-medium text-nowrap whitespace-nowrap"
              >
             Renewal Date-Time
              </th> */}
              <th
                scope="col"
                className="px-6 py-3  text-center  text-base sm:text-lg font-medium text-nowrap whitespace-nowrap"
              >
                Coupons
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-base  text-center  sm:text-lg font-medium text-nowrap whitespace-nowrap"
              >
                Payment
              </th>
            </tr>
          </thead>

          <tbody>
            {DealsDetails?.UserOneOfPlans?.length === 0 ? (
              <div className="flex items-center justify-center w-full h-14 font-semibold text-lg">
                No Plans !{" "}
              </div>
            ) : (
              DealsDetails?.UserOneOfPlans?.map((plan, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0
                      ? "bg-[rgba(12,15,17,0.06)]"
                      : "bg-transparent"
                  }`}
                >
                  <th
                    scope="row"
                    className="px-6 py-4 text-lg sm:text-xl text-center font-medium text-primary-blue whitespace-nowrap"
                  >
                    {plan?.plan_name}
                  </th>
                  <td className="px-6 py-4  text-center  text-base sm:text-lg text-primary-dark-blue font-medium whitespace-nowrap text-nowrap">
                    {moment(plan?.start_date)
                      .local()
                      .format("MMMM Do YYYY, hh:mm A") || "--"}
                  </td>
                  {/* <td className="px-6 py-4 text-base  text-center  sm:text-lg text-primary-blue font-medium whitespace-nowrap text-nowrap">
                    {moment(plan?.end_date)
                      .local()
                      .format("MMMM Do YYYY, hh:mm A") || "--"}
                  </td> */}
                  <td className="px-6 py-4  text-center  flex justify-center">
                    <div className="p-4 bg-light-blue text-lg font-semibold rounded-xl w-fit text-primary-blue whitespace-nowrap text-nowrap">
                      {plan?.payment_history?.token}
                    </div>
                  </td>
                  <td className="px-6 py-4 text-center">
                    <button
                      onClick={() => ViewMoreDetails(plan)}
                      className="py-4 px-6 bg-dark-blue text-white rounded-md whitespace-nowrap text-nowrap"
                    >
                      View More
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
          {showViewMore && (
            <ViewmoreModal
              showViewMoreModal={closeViewMore}
              data={viewMoreData}
            />
          )}
        </table>
 
    </div>}
    
    
    </>
  );
};

export default OneOffPlans;
