import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { EffectFade, Pagination, Autoplay } from "swiper/modules";
import { BaseUrl } from "../ReduxToolkit/Apis";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import dummylogo from "../images/Logo.png";
import "./Slider.css"
const Slider = ({ data }) => {
  const swiperRef = useRef(null); // Reference for Swiper instance

  const handleSlideChange = (swiper) => {
    // Restart slider when the last slide is reached
    if (swiper.isEnd) {
      setTimeout(() => {
        swiper.slideTo(0); // Go back to the first slide
      }, 2500); // Delay matches autoplay delay
    }
  };

  return (
    <Swiper
      onSwiper={(swiper) => {
        swiperRef.current = swiper; // Save the Swiper instance
      }}
      onSlideChange={handleSlideChange} // Triggered on slide change
      pagination={{
        dynamicBullets: true,
      }}
      
      // effect={"fade"}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      loop={false} // Disable loop to control reset behavior
      modules={[Autoplay, Pagination, EffectFade]}
      className="mySwiper"
    >
      {data?.map((el, index) => (
        <SwiperSlide key={index} className="">
          <LazyLoadImage
            effect="blur"
            height={"100%"}
            width={"100%"}
            src={`${BaseUrl}/${el?.image}`}
            onError={(e) => {
              e.target.src = dummylogo; // Fallback image if loading fails
            }}
            className=" w-full "
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
