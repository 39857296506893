import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseUrl,  } from "../../Apis";
let getUserdata=JSON.parse(localStorage.getItem("SaveOn_Web_Cread"))

export const Getgiveaways = createAsyncThunk("deals/Getgiveaways", async (count) => {
  
  try {
    let data = await axios.get(`${BaseUrl}/api/giveaway/get-giveaways?count=${count}`);
    return data.data;
  } catch (error) {
    throw error;
  }
});
export const GetAboutusDetails = createAsyncThunk(
  "deals/GetAboutusDetails",
  async () => {
    try {
      let data = await axios.get(`${BaseUrl}/api/content/get-about`);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const GetFaqsDetails = createAsyncThunk(
  "deals/GetFaqsDetails",
  async () => {
    try {
      let data = await axios.get(`${BaseUrl}/api/content/get-about`);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const GetUserCoupens = createAsyncThunk(
  "deals/GetUserCoupens",
  async ({apikey,token}) => {
    console.log(apikey,"apikey");
    
    try {
      // let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey:apikey,
          token:token
        },
      };
      let data = await axios.get(`${BaseUrl}/api/user/get-my-coupons`, config);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);
export const DawonloadCoupen = createAsyncThunk(
  "deals/DawonloadCoupen",
  async (coupenData) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey:apikey,
          token:token
        },
      };
      let data = await axios.post(`${BaseUrl}/api/offer/download-coupon`,coupenData, config);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const GetOneOffplans = createAsyncThunk(
  "deals/GetOneOffplans",
  async ({apikey,token}) => {
    try {
      // let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey:apikey,
          token:token,
        },
      };
      let data = await axios.get(
        `${BaseUrl}/api/user/get-one-off-plans`,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const GetFaqsListData = createAsyncThunk(
  "deals/GetFaqslistData",
  async () => {
    try {
      // let { apikey, token } = getUserdata;
      // const config = {
      //   headers: {
      //     apikey,
      //     token,
      //   },
      // }
      let data = await axios.get(`${BaseUrl}/api/content/get-faq-list`);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);
