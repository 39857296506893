import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseUrl } from "../../Apis";
export const GetAllPartners = createAsyncThunk(
  "partners/GetAllPartners",
  async (params) => {
 
    
    try {
      let data = await axios.get(`${BaseUrl}/api/partner/our-partners`, {
        params: params
      });
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)

export const GetPartnerById = createAsyncThunk(
  "partners/GetPartnerById",
  async (id) => {
    try {
      let data = await axios.get(`${BaseUrl}/api/partner/partner-details?partner_id=${id}`);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)



export const GetPartnerTermsandConditions = createAsyncThunk(
  "partners/GetPartnerTermsandConditions",
  async (id) => {
    try {
      let data = await axios.get(`${BaseUrl}/api/partner/get-partner-tc?partner_id=${id}`);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
)

