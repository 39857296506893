import React from "react";
 
import saveIcon from "../../images/save.png";
import backIcon from "../../images/back.png";
import signupIcon from "../../images/signup.png";
import frameOne from "../../images/frameOne.png";
import frameTwo from "../../images/frameTwo.png";
import frameThree from "../../images/frameThree.png";
import SectionTitle from "../SectionTitle";
import HowtoCard from "../HowtoCard";

const Howto = () => {
  return (
    <section className="h-full bg- w-full py-2">
      <div className="w-[85%] max-w-[1500px] mx-auto py-[2vh]">
        <SectionTitle title={"How to use?"} />
        <div className="mt-8 lg:mt-14  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-14 gap-y-14 md:gap-y-14 lg:gap-y-14  lg:gap-x-16   m-auto    sm:gap-8   ">
          <HowtoCard
            frame={frameOne}
            title={"Sign Up"}
            desc={
              "Purchase one-off access or subscribe to get access to our fantastic deals"
            }
            image={signupIcon}
          />
          <HowtoCard
            frame={frameTwo}
            title={"Save Instantly"}
            desc={
              "Make your hard-earned money go further and start enjoying life more"
            }
            image={saveIcon}
          />
          <HowtoCard
            frame={frameThree}
            title={"Give Back"}
            desc={
              "Joining us means you will be part of a community that gives back to the kiwi whanau"
            }
            image={backIcon}
          />
        </div>
      </div>
    </section>
  );
};

export default Howto;
