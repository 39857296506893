import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import tag from "../../images/tag.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  Login,
  ResetPassword,
} from "../../ReduxToolkit/Slices/Auth/Auth.action";

import toast, { Toaster } from "react-hot-toast";
import { RxCrossCircled } from "react-icons/rx";

const ForgotPassword = ({ closeForgotPassModal, switchToLogin }) => {
  const modalRef = useRef(null);
  let dispatch = useDispatch();
  let [authload, setauthload] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required !"),
    }),
    onSubmit: (values) => {
      let forgotpasswordData = new FormData();
      forgotpasswordData.append("email", values?.email);
      setauthload(true);
      dispatch(ResetPassword(forgotpasswordData))
        .unwrap()
        .then((result) => {
          if (result?.s == 1) {
            setauthload(false);
            toast(`${result?.m}`, {
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
                textAlign: "center",
                background: "rgba(255,48,147,1)",
              },
            });
            closeForgotPassModal();
          } else {
            setauthload(false);
            toast.error(result?.m, {
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
                // background: "rgba(255,48,147,1)",
              },
            });
            //   closeForgotPassModal();
          }
        });
    },
  });

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeForgotPassModal();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeForgotPassModal]);

  return (
    <div className="fixed z-10 inset-0  bg-[rgba(54,0,17,0.84)] flex items-center justify-center">
      <div
        ref={modalRef}
        className="bg-white rounded-[32px] p-5 md:p-8 relative w-[90%] sm:w-[500px] tracking-wide border-dashed border-2 border-[rgba(14,47,67,0.5)] shadow-[10px_8px_0_0_rgba(255,255,255,0.42)]"
      >
        <div className=" text-white text-base md:text-2xl absolute text-nowrap transform -translate-x-1/2 -translate-y-1/2 -top-7 md:-top-10 left-1/2 flex gap-1">
          <div className="flex items-center gap-2">
            <span className="text-[#fff]">Forgot password </span>{" "}
            <img src={tag} width={25} alt="tag" />
            <div className="pl-4">
              <RxCrossCircled
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                onClick={closeForgotPassModal}
              />
            </div>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="relative">
            <label htmlFor="email" className="text-left text-[#082F49D1] mb-2">
              Email Address
            </label>
            <input
              className="mt-2 border border-gray-300 rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
              type="email"
              name="email"
              id="email"
              placeholder="Enter email address"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{ background: "rgba(254,241,248,1)" }}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm mt-2">
                {formik.errors.email}
              </div>
            ) : null}
          </div>

          <div className="mt-4 flex justify-between items-center">
            <div></div>
            <div>
              <button
                disabled={authload}
                className=" bg-dark-red-bg py-2 px-6 text-[#FFF] rounded-lg"
                type="submit"
              >
                {authload ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
