import React, { useEffect } from "react";

import SectionTitle from "../SectionTitle";
import PlanCard from "../PlanCard";
import { useDispatch, useSelector } from "react-redux";
import { GetsubscriptionPlans } from "../../ReduxToolkit/Slices/Subscription/Subscription.action";
 
const Plans = () => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetsubscriptionPlans());
  }, []);
  let Subscription = useSelector((store) => store?.Subscription);

  return (
    <section className="h-full bg-[#F6F6F6] w-full py-8 lg:py-12">
      <div className="w-[90%] max-w-[1500px] mx-auto">
        <div className="flex justify-start items-center">

        <SectionTitle title={"One-off Payment"} />
        </div>
        <div className="mt-12 lg:mt-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-14 md:gap-y-14 lg:gap-y-18 m-auto gap-6 md:gap-2 lg:gap-6">
          {Subscription?.planDetails?.slice(0, 3).map((el, index) => (
            <PlanCard
              key={index}  
              planData={el}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Plans;
