import React, { useEffect, useState } from "react";
import offerbackgraundbanner from "../../images/spacialofferback.png";
import partnersimg from "../../images/amazon.png";
import searchIcon from "../../images/search.svg";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import Titlebar from "../../components/Titlebar";
import { GetAllPartners } from "../../ReduxToolkit/Slices/Partners/Partners.action";
import { useDispatch, useSelector } from "react-redux";
import { BaseUrl } from "../../ReduxToolkit/Apis";
import { GetHomepageCategory } from "../../ReduxToolkit/Slices/Offers/Offers.action";

import toast, { Toaster } from "react-hot-toast";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FiLoader } from "react-icons/fi";
import partnerlogodummy from "../../images/logoplaceholder.png";
import useDebounce from "../../Funtions/useDebounce";

const OffersPage = () => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllPartners());
    dispatch(GetHomepageCategory());
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let partners = useSelector((store) => store?.Partners);
  let Offer = useSelector((store) => store?.Offers);
  const [searchCategory, setSearchCategory] = useState("");
  const debouncedSearchCategories = useDebounce(searchCategory, 1000);
  useEffect(() => {
    if (debouncedSearchCategories || debouncedSearchCategories === "") {
      dispatch(
        GetHomepageCategory({
          searchkey: debouncedSearchCategories.trim(),
        })
      );
    }
  }, [debouncedSearchCategories]);

  return (
    <div className="bg-[#FAFAFA]">
      {Offer?.loading && (
        <div className="fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <span className="loader"></span>
        </div>
      )}

      <Toaster />
      <div className="w-full relative m-auto ">
        <LazyLoadImage
          effect="blur"
          className=" object-cover w-full h-[40vh] md:h-[60vh]"
          objectFit="cover"
          src={offerbackgraundbanner}
          alt="giveaway"
          height={"100%"}
          width={"100%"}
        />
        <div className="absolute top-0 m-auto w-full   h-full flex">
          <div className="  text-zinc-50 w-full  md:1/2 flex flex-col justify-center align-middle text-center m-auto ">
            <h2 className="font-extrabold text-cxl md:text-c_xl  ">Offers</h2>
            {/* <p className="text-csm  md:text-c_sm  w-[95%] md:w-[50%] lg:w-[30%]  text-center m-auto">
              It is a long established fact that a reader will be distracted by
              the readable content of a.
            </p> */}
          </div>
        </div>
      </div>

      <div className="w-[90%] m-auto bg-">
        <Titlebar title={"Deals Partners"} route={"/ourpartners/"} />
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 gap-y-6 md:gap-y-10 lg:gap-y-14   m-auto pt-8 md:pt-16 pb-8 md:pb-16 box-border">
          {partners?.AllPartners?.r?.slice(0, 4).map((partner, i) => (
            <Link to={`/ourpartners/${partner?.id}`} key={i}>
              <div className="shadow-[0px_1px_8px_0px_rgba(0,0,0,0.08)] group relative">
                <div className="h-42 md:h-52 w-full">
                  <LazyLoadImage
                    effect="blur"
                    className="h-full w-full"
                    objectFit="cover"
                    src={
                      partner?.partner_logo
                        ? `${BaseUrl}${partner?.partner_logo}`
                        : partnerlogodummy
                    }
                    alt="giveaway"
                    height={"100%"}
                    width={"100%"}
                  />
                </div>
                <div className="text-center   p-2 md:p-4 flex justify-center align-middle bg-light-blue">
                  <p className="text-clg md:text-c_lg font-semibold overflow-hidden text-ellipsis whitespace-nowrap w-full">
                    {partner?.name ? partner?.name : "---"}
                  </p>
                </div>
                <div className="absolute bottom-0 left-0 w-full border-b-4 border-primary-blue group-hover:border-b-8 duration-200 ease-linear" />
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* --------------- Search Section ----------- */}
      <div className="py-12 w-full bg-[#fff]">
        <div className="w-[90%] max-w-[1500px] mx-auto">
          <div className="flex flex-col md:flex-row gap-3 py-8">
            <div className="relative w-full md:w-[45%]">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 rounded-lg">
                <img
                  src={searchIcon}
                  alt="search icon"
                  className="w-5 h-5 text-gray-400"
                />
              </div>
              <input
                onChange={(e) => setSearchCategory(e.target.value)}
                onClear={() => setSearchCategory("")}
                type="text"
                value={searchCategory.trimStart()}
                placeholder="Search by categories"
                style={{
                  background: "rgba(254,241,248,1)",
                  color: "rgba(110, 129, 141, 1)",
                }}
                className="w-full h-inh pl-10 py-4 text-sm text-gray-700 placeholder-gray-400 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-200 border focus:border-[rgba(8,47,73,0.24)]"
              />
            </div>
            {/* <button
              disabled={!searchCategory.trim() ? true : false || Offer?.loading}
              onClick={HandeldSearchCategory}
              className="bg-dark-red-bg w-[40%] md:w-auto md:px-10 py-3 rounded-lg text-white"
            >
              {Offer?.loading ? <FiLoader /> : "Search"}
            </button>
            {searchCategory.trim() || issearchCategory ? (
              <button
                disabled={Offer?.loading}
                onClick={ResetCategory}
                className="bg-dark-red-bg w-[40%] md:w-auto md:px-10 py-3 rounded-lg text-white"
              >
                {Offer?.loading ? <FiLoader /> : "Reset"}
              </button>
            ) : (
              ""
            )} */}
          </div>
        </div>
      </div>

      {/* --------------- Dinining Content ----------- */}
      {Offer?.HomepageCategorys?.length > 0 ? (
        Offer?.HomepageCategorys.map((category, i) => (
          <section key={i} className="h-full bg-white w-full ">
            <div className="w-[90%] mx-auto">
              {category?.offers.length >= 4 ? (
                <Titlebar
                  title={`${category?.name}`}
                  route={`/offers/alloffers/${category?.id}`}
                />
              ) : (
                <Titlebar title={`${category?.name}`} />
              )}
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 gap-y-6 md:gap-y-10 lg:gap-y-14 m-auto pt-8 md:pt-8 pb-8 md:pb-16 box-border">
                {category?.offers?.slice(0, 3).map((offer) => (
                  <Link key={offer.id} to={`/offers/${offer?.id}`}>
                    <Card type={"dining"} data={offer} />
                  </Link>
                ))}
              </div>
            </div>
          </section>
        ))
      ) : (
        <div className="flex w-full h-44 justify-center items-center font-semibold text-lg">
          No categories available.
        </div>
      )}
    </div>
  );
};

export default OffersPage;
