import React, { useEffect, useRef, useState } from "react";
import offerbackgraundbanner from "../images/aboutusbanner.png";
import blinkIcon from "../images/blink.svg";
import sampleVideo from "../images/sample.mp4";
import pattern from "../images/pattern.svg";
import minus from "../images/minus.svg";
import plus from "../images/plus.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAboutusDetails,
  GetFaqsListData,
} from "../ReduxToolkit/Slices/Deals/Deals.action";
import { BaseUrl } from "../ReduxToolkit/Apis";
import playBtn from "../images/playbtn.png";
const About = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  let dispatch = useDispatch();
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const videoRef = useRef(null);
  const togglePlay = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };
  const handleVideoEnd = () => {
    setIsPlaying(false); // Set isPlaying to false when the video ends
  };
  useEffect(() => {
    const video = videoRef?.current;
    video?.addEventListener("ended", handleVideoEnd);
    return () => {
      video?.removeEventListener("ended", handleVideoEnd);
    };
  }, []); // Add event listener on mount and cleanup on unmount
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(GetAboutusDetails());
    dispatch(GetFaqsListData());
  }, [dispatch]);
  let aboutUsDetails = useSelector((store) => store?.Deals);
  return (
    <div className="bg-[#FAFAFA] overflow-hidden">
      <div className="w-full relative mx-auto ">
        <LazyLoadImage
          effect="blur"
          className="object-cover w-full h-[40vh] md:h-[60vh]"
          src={offerbackgraundbanner}
          alt="giveaway"
          height={"100%"}
          width={"100%"}
        />
        <div className="absolute top-0 m-auto w-full h-full flex">
          <div className="text-zinc-50 w-full md:1/2 flex flex-col justify-center align-middle text-center m-auto">
            <h2 className="font-extrabold text-cxl md:text-c_xl">About Us</h2>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="w-[90%] max-w-[1500px] mx-auto py-6">
          <div className="flex flex-col lg:flex-row w-full gap-4 items-center">
            <ol className="relative  w-full lg:w-[50%] h-fit">
              <h2
                className="mb-4    "
                dangerouslySetInnerHTML={{
                  __html: aboutUsDetails?.aboutus?.content
                    ? aboutUsDetails?.aboutus?.content
                    : ".....",
                }}
              />
            </ol>
            <div className="w-full lg:w-[50%]   relative">
              {aboutUsDetails?.aboutus?.media ? (
                <video
                  ref={videoRef}
                  className="relative rounded-3xl object-cover z-"
                  onClick={togglePlay}
                >
                  <source
                    src={`${BaseUrl}${aboutUsDetails?.aboutus?.media}`}
                    type="video/mp4"
                  />
                </video>
              ) : (
                "OOho video not found !"
              )}
              {!isPlaying && (
                <div
                  className="absolute inset-0  bg-opacity-40 z-55 flex justify-center items-center"
                  onClick={togglePlay}
                >
                  <img
                    src={playBtn}
                    alt="Play"
                    className="h-14 w-14 cursor-pointer"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* -------- FAQ Section -------- */}

      <div className="bg-[#F3F3F3] w-full">
        <div className="w-[90%] max-w-[1500px] mx-auto py-14">
          <h2 className="text-[#0E2F43] font-bold text-lg md:text-cxl">FAQs</h2>
          <div
            className="w-full md:w-[100%] lg:w-[80%]"
            id="accordion-collapse"
            data-accordion="collapse"
          >
            {aboutUsDetails?.FaqsList?.map((el, index) => (
              <div className="m-4" key={index}>
                <h2 id={`accordion-collapse-heading-${index}`}>
                  <button
                    type="button"
                    className={`flex items-center ${
                      openIndex === index ? "bg-white" : "transparent"
                    } justify-between w-full p-2 font-medium rtl:text-right text-gray-500 rounded-t-xl ${
                      openIndex === null && "border-b-2"
                    } gap-3`}
                    onClick={() => toggleAccordion(index)}
                    aria-expanded={openIndex === index}
                    aria-controls={`accordion-collapse-body-${index}`}
                  >
                    <span className="text-dark-text text-start font-semibold">
                      {el?.title}
                    </span>
                    <img
                      width={"25px"}
                      className={`transition-transform duration-300 ${
                        openIndex === index ? "rotate-180" : ""
                      }`}
                      src={openIndex === index ? minus : plus}
                      alt="dropdown arrow"
                    />
                  </button>
                </h2>
                <div
                  id={`accordion-collapse-body-${index}`}
                  className={`${
                    openIndex === index ? "block" : "hidden"
                  } p-2 text-csm rtl:text-right text-gray-500 bg-white rounded-b-xl`}
                  aria-labelledby={`accordion-collapse-heading-${index}`}
                >
                  <p
                    className="text-primary-blue "
                    dangerouslySetInnerHTML={{
                      __html: el?.description ? el?.description : ".....",
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
