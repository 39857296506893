import { Link, useLocation } from "react-router-dom";
import Card from "../../components/Card";
import Titlebar from "../../components/Titlebar";
import searchIcon from "../../images/search.svg";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetOffersByCategoryid } from "../../ReduxToolkit/Slices/Offers/Offers.action";
import useDebounce from "../../Funtions/useDebounce";
import backIcon from "../../images/back-arrow.png";
import nextIcon from "../../images/right-arrow.png";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";

const AllOffers = () => {
  const location = useLocation();
  const idMatch = location?.pathname.match(/\/alloffers\/(\d+)$/);
  const id = idMatch ? idMatch[1] : null;

  const [searchOffer, setsearchOffer] = useState("");
  const debouncedSearchOffer = useDebounce(searchOffer, 1000);
  const [count, setcount] = useState(0); // Offset for pagination

  const dispatch = useDispatch();
  const OfferList = useSelector((store) => store?.Offers);

  const itemsPerPage = 12; // Number of items per page
  const totalItems = OfferList?.OffersListByCategoryId?.count || 0; // Total count of items

  useEffect(() => {
    dispatch(GetOffersByCategoryid({ Category_id: id, type: 1, count }));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (debouncedSearchOffer || debouncedSearchOffer === "") {
      dispatch(
        GetOffersByCategoryid({
          Category_id: id,
          type: 1,
          searchkey: debouncedSearchOffer.trim(),
          count,
        })
      );
    }
  }, [debouncedSearchOffer, id, count]);

  const handleNext = () => {
    if (count + itemsPerPage < totalItems) {
      setcount((prevCount) => prevCount + itemsPerPage);
    }
  };

  const handlePrev = () => {
    setcount((prevCount) => (prevCount > 0 ? prevCount - itemsPerPage : 0));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      {OfferList?.loading && (
        <div className="fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <span className="loader"></span>
        </div>
      )}
      {/* --------------- Search Section ----------- */}
      <div className="py-14 w-full bg-bg-color mt-14">
        <div className="w-[90%] max-w-[1500px] mx-auto">
          <div className="flex flex-col md:flex-row gap-3 py-8">
            <div className="relative w-full md:w-[45%]">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 rounded-lg">
                <img
                  src={searchIcon}
                  alt="search icon"
                  className="w-5 h-5 text-gray-400"
                />
              </div>
              <input
                onChange={(e) => setsearchOffer(e.target.value)}
                type="text"
                value={searchOffer.trimStart()}
                placeholder="Search by offers"
                className="w-full h-inh pl-10 py-4 text-sm text-gray-700 placeholder-gray-400 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-200 border focus:border-[rgba(8,47,73,0.24)]"
              />
            </div>
          </div>
        </div>
      </div>
      {/* --------------- Content Section ----------- */}
      <div className="w-[90%] m-auto pt-14 ">
        {OfferList?.OffersListByCategoryId?.r?.length > 0 ? (
          <Titlebar
            title={OfferList?.OffersListByCategoryId?.r[0]?.offer_category}
          />
        ) : (
          ""
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 gap-y-6 md:gap-y-10 lg:gap-y-14 m-auto md:pt-8 pb-8 md:pb-16 box-border">
          {OfferList?.OffersListByCategoryId?.r?.map((offer, i) => (
            <Link key={offer.id} to={`/offers/${offer?.id}`}>
              <Card data={offer} />
            </Link>
          ))}
        </div>

        {totalItems > itemsPerPage && (
          <div className="flex justify-end items-center gap-2 w-[90%] max-w-[1500px] mx-auto py-8">
            <button
              onClick={handlePrev}
              disabled={count < itemsPerPage}
              className={` p-1 cursor-pointer border hover:border-black text-white  bg-primary-blue border-primary-blue rounded-full   ${
                count < itemsPerPage ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <GrFormPreviousLink />
            </button>

            <p>
              Page: {Math.ceil(count / itemsPerPage) + 1} of{" "}
              {Math.ceil(totalItems / itemsPerPage)}
            </p>
            <button
              onClick={handleNext}
              disabled={count + itemsPerPage >= totalItems}
              className={` p-1 cursor-pointer border hover:border-black  text-white  bg-primary-blue border-primary-blue rounded-full   ${
                count + itemsPerPage >= totalItems
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              <GrFormNextLink />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllOffers;
