import React, { useEffect, useState } from "react";
import productdetailsbannerimage from "../../images/productdetailsbannerimage.png";
import partnersimg from "../../images/amazon.png";
import locationlogo from "../../images/location.svg";
import phonelogo from "../../images/phone.svg";
import gmailLogo from "../../images/gmail.svg";
import clocklogo from "../../images/clock.svg";
import fbIcon from "../../images/Facebook.svg";
import tiktokIcon from "../../images/Tiktok.svg";
import instaIcon from "../../images/Instagram.svg";
import Card from "../../components/Card";
import PlanCard from "../../components/PlanCard";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetPartnerById } from "../../ReduxToolkit/Slices/Partners/Partners.action";
import { formatTime } from "../../Funtions/CommonFunc";
import { BaseUrl } from "../../ReduxToolkit/Apis";
import { GetOffersByPartnerId } from "../../ReduxToolkit/Slices/Offers/Offers.action";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import staticimage from "../../images/Logo.svg";
import Plans from "../../components/plans/Plans";
import ReactStars from "react-rating-stars-component";
import twitter from "../../images/twitter.svg";
import Slider from "../../components/Slider";
import logoplaceholder from "../../images/logoplaceholder.png";
const PartnerDetails = () => {
  let location = useLocation();
  const idMatch = location?.pathname.match(/\/ourpartners\/(\d+)$/);
  const id = idMatch ? idMatch[1] : null;
  let dispatch = useDispatch();
let [ratingValue,setratingValue]=useState(null)
  useEffect(() => {
    dispatch(GetPartnerById(id)).unwrap().then((result)=>{
      console.log(result,"values are stored ")
      setratingValue(result?.r?.rating)
    })
    dispatch(GetOffersByPartnerId(id));
    window.scrollTo(0, 0);
  }, [dispatch]);

  let partnerData = useSelector((store) => store?.Partners);
  let offersData = useSelector((store) => store?.Offers);
  const [isExpanded, setIsExpanded] = useState(false);
  const description = partnerData?.PartnerDetailsById?.description || ".....";

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };
  console.log(partnerData,"partnerData");
  
  return (
    <div>
      {/* --- Intro Section ---- */}
      <div className="w-full relative m-auto">
        <div className="h-[27vh] md:h-[44vh] lg:h-[95vh] mt-[72px]    ">
          <Slider data={partnerData?.PartnerDetailsById?.images} />
        </div>
      </div>
      {/* --- Partner details content Section ---- */}
      <div className="flex flex-col lg:flex-row w-full">
        <div className="bg-slate-50 flex flex-col lg:flex-row w-full lg:w-3/5 justify-center gap-10 p-8 lg:p-8">
          <div className="w-full flex flex-col sm:flex-row justify-start   items-center gap-6 md:gap-14">
            <img
              src={
                partnerData?.PartnerDetailsById?.partner_logo
                  ? `${BaseUrl}${partnerData?.PartnerDetailsById?.partner_logo}`
                  : logoplaceholder
              }

              alt="partner_Logo"
              className="w-full hidden rounded-lg shadow-lg md:block max-w-[400px]"
            />
            <div className="flex flex-col justify-start gap-2 w-full md:w-1/2">
              <div className="flex item-center justify-start gap-2">
                <img
                  src={
                    partnerData?.PartnerDetailsById?.partner_logo
                      ? `${BaseUrl}${partnerData?.PartnerDetailsById?.partner_logo}`
                      : logoplaceholder
                  }
                  alt="partner_Logo"
                  className={`w-full rounded-lg shadow-lg block  md:hidden max-w-[100px]`}
                />
                <h2  className="text-c_md font-semibold leading-7 md:leading-2   w-full   lg:text-cx xl:text-c_x text-dark-blue">
                  {partnerData?.PartnerDetailsById?.name
                    ? partnerData?.PartnerDetailsById?.name
                    : "------"}
                </h2>
              </div>
              <p
                className={`text-[#6E818D] ${
                  isExpanded ? "" : "line-clamp-2 overflow-hidden"
                } text-dark-text text-csm lg:text-c_sm`}
                dangerouslySetInnerHTML={{ __html: description }}
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: isExpanded ? "unset" : 2, // Show 2 lines initially
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              />
              {description && (
                <span
                  onClick={toggleDescription}
                  className="text-primary-blue cursor-pointer"
                >
                  {isExpanded ? "View Less" : "View More"}
                </span>
              )}

              <div className="  w-full flex items-center gap-2">
                {ratingValue? (
                  <ReactStars
                    count={5}
                    value={ratingValue }
                    size={34}
                    edit={false}
                    activeColor="#ffd700"
                  />
                ) : (
                  ""
                )}
                <span className="text-primary-blue font-semibold text-[18px] ">
                  {partnerData?.PartnerDetailsById?.rating}
                </span>
              </div>
              <div className="flex gap-2 ">
                {partnerData?.PartnerDetailsById?.f_url !== null ? (
                  <a
                    href={partnerData?.PartnerDetailsById?.f_url}
                    className="cursor-pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="w-[40px] transition-transform duration-300 hover:scale-110 hover:border rounded-full border-primary-blue"
                      src={fbIcon}
                      alt="facebook"
                    />
                  </a>
                ) : (
                  ""
                )}
                {partnerData?.PartnerDetailsById?.i_url !== null ? (
                  <a
                    href={partnerData?.PartnerDetailsById?.i_url}
                    className="cursor-pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="w-[40px] transition-transform duration-300 hover:scale-110 hover:border rounded-full border-primary-blue"
                      src={instaIcon}
                      alt="instagram"
                    />
                  </a>
                ) : (
                  ""
                )}
                {partnerData?.PartnerDetailsById?.x_url !== null ? (
                  <a
                    href={partnerData?.PartnerDetailsById?.x_url}
                    className="cursor-pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="w-[40px] transition-transform duration-300 hover:scale-110 hover:border rounded-full border-primary-blue"
                      src={twitter}
                      alt="instagram"
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[rgba(254,241,248,1)] flex w-full lg:w-2/5 flex-col gap-4 p-8 lg:p-16">
          {partnerData?.PartnerDetailsById?.address ? (
            <div className="flex gap-4 flex-col">
              <p className="text-c_md text-primary-blue font-semibold">
                {" "}
                Address
              </p>

              <a
                className="cursor-pointer relative transition-all duration-300 hover:underline decoration-transparent hover:decoration-dark-blue"
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  partnerData?.PartnerDetailsById?.address
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex gap-2 items-start">
                  <img
                    src={locationlogo}
                    alt="location logo"
                    className="w-[32px] h-[32px]"
                  />
                  <p className="text-csm lg:text-c_sm text-dark-blue">
                    {partnerData?.PartnerDetailsById?.address
                      ? partnerData?.PartnerDetailsById?.address
                      : "------"}
                  </p>
                </div>
              </a>
            </div>
          ) : (
            ""
          )}
          <div className="flex flex-col md:flex-row gap-4">
            {" "}
            <a
              className="cursor-pointer relative transition-all duration-300 hover:underline decoration-transparent hover:decoration-dark-blue"
              href={`tel:+${partnerData?.PartnerDetailsById?.phno_cc} ${partnerData?.PartnerDetailsById?.phno}`}
            >
              <div className="flex gap-2 items-center">
                <img
                  src={phonelogo}
                  alt="phone logo"
                  className="w-[32px] h-[32px]"
                />

                <p className="text-csm lg:text-c_sm text-dark-blue">
                  +
                  {partnerData?.PartnerDetailsById?.phno_cc
                    ? partnerData?.PartnerDetailsById?.phno_cc
                    : "------"}{" "}
                  <span>
                    {partnerData?.PartnerDetailsById?.phno
                      ? partnerData?.PartnerDetailsById?.phno
                      : "------"}
                  </span>
                </p>
              </div>
            </a>
            <a
              className="cursor-pointer relative transition-all duration-300 hover:underline decoration-transparent hover:decoration-dark-blue"
              href={`mailto:${partnerData?.PartnerDetailsById?.email}`}
            >
              <div className="flex gap-2 items-center">
                <img
                  src={gmailLogo}
                  alt="gmail logo"
                  className="w-[32px] h-[32px] "
                />

                <p className="text-csm lg:text-c_sm text-dark-blue">
                  {partnerData?.PartnerDetailsById?.email
                    ? partnerData?.PartnerDetailsById?.email
                    : "------"}
                </p>
              </div>
            </a>
          </div>
          <div className="flex gap-2 items-center">
            <img
              src={clocklogo}
              alt="clock logo"
              className="w-[32px] h-[32px]"
            />
            <p className="text-csm lg:text-c_sm text-dark-blue">
              <span>
                {moment(
                  partnerData?.PartnerDetailsById?.start_time,
                  "HH:mm"
                ).format("hh:mm A")}{" "}
                -{" "}
                {moment(
                  partnerData?.PartnerDetailsById?.end_time,
                  "HH:mm"
                ).format("hh:mm A")}
              </span>
            </p>
          </div>
        </div>
      </div>

      {/* ----------- Coupons Listing --------------  */}
      {offersData?.OffersByPartnerId?.length > 0 ? (
        <div className="w-[90%] max-w-[1500px] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 gap-y-6 md:gap-y-10 lg:gap-y-14 m-auto pt-8   pb-8   box-border">
          {offersData?.OffersByPartnerId?.map((offerdata, i) => (
            <div key={i}>
              <Link key={offerdata.id} to={`/offers/${offerdata?.id}`}>
                <Card data={offerdata} />
              </Link>
            </div>
          ))}
        </div>
      ) : (
        ""
      )}

      {/* ----------- One-off Plans --------------  */}
      <div className="   m-auto border-2 bg-[rgba(243,243,243,1)]">
        <div className="w-[90%] max-w-[1500px] mx-auto">
          {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-14 lg:gap-20 md:gap-14 pt-14"> */}
          <Plans />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default PartnerDetails;
