import React from "react";

import appStore from "../../images/appstore.png";
import playStore from "../../images/playstore.png";
import arrowornaments from "../../images/arrowornaments.svg";

const Download = () => {
  return (
    <section className="h-full bg-[#F6F6F6] w-full py-14">
      <div className="w-[90%] max-w-[1500px] mx-auto flex items-center justify-center relative ">
        <div className="">
          <img
            alt="arrowornaments"
            className="w-[40px] sm:w-[55px] h-[60px] absolute  -top-12 md:top-0 left-0"
            src={arrowornaments}
          />

          <div className="text-center md:w-[500px] flex flex-col gap-6 leading-tight items-center">
            <h3 className="font-bold text-[26px] md:text-[30px] xl:text-[36px] text-dark-text">
              Download
            </h3>
            <h3 className="font-semibold text-[20px] md:text-[22px] xl:text-[28px] text-dark-red-bg">
              Our New App!
            </h3>
            <p className="text-[14px] md:text-[16px] xl:text-[18px] font-medium text-desc-text text-wrap">
            Don’t miss out on amazing savings! Download now to access exclusive coupons for local restaurants and services. Start saving today !
            </p>
            <div className="flex items-center gap-4">
              <img
                alt="appStore"
                className="max-w-24 md:max-w-32 xl:max-w-40 transition-transform duration-500 transform hover:translate-y-2"
                src={appStore}
              />
              <img
                alt="playStore"
                className="max-w-24 md:max-w-32 xl:max-w-40 transition-transform duration-500 transform hover:translate-y-2"
                src={playStore}
              />
            </div>
          </div>

          <img
            className="w-[40px] sm:w-[55px] h-[60px] absolute right-0 -bottom-12 md:bottom-0"
            src={arrowornaments}
          />
        </div>
      </div>
    </section>
  );
};

export default Download;
