import React, { useEffect, useRef } from "react";
import Lottie from "lottie-react";
import congratsLottieFile from "../../images/congratsLottie.json";
import giftgif from "../../images/giftgif.json";
import { RxCrossCircled } from "react-icons/rx";

const CongratsModal = ({ showCongratsModal, setShowCongratsModal, data }) => {
  const modalRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowCongratsModal(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    const timer = setTimeout(() => {
      setShowCongratsModal(false);
    }, 5000);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      clearTimeout(timer);
    };
  }, [showCongratsModal, setShowCongratsModal]);
  if (!showCongratsModal) {
    return null;
  }
  let closethismodal = () => {
    setShowCongratsModal(false);
  };
  return (
    <div className="fixed z-[105] inset-0 bg-[rgba(54,0,17,0.84)] flex items-center justify-center">
      <div
        ref={modalRef}
        className="bg-transparent rounded-[32px] p-10 relative w-[90%] sm:w-[500px] tracking-wide"
      >
        <div className="flex justify-end absolute right-4 top-4 "></div>
        <div className="flex flex-col items-center gap-2">
          <div className="-mt-4">
            <Lottie animationData={giftgif} loop={true} />
          </div>
          <div className="-mt-20">
            <Lottie animationData={congratsLottieFile} loop={true} />
          </div>
          <div className="-mt-20 text-white">
            {/* {  data?.purchase_units[0]!==undefined ||  data?.purchase_units[0]!==null? data?.purchase_units[0]?.description:""}  */}
            Your Plan is Active now !!
          </div>
          <button
            className="bg-dark-blue mt-4 py-3 px-4 rounded-lg w-fit text-white transition-all duration-300 ease-in-out hover:bg-primary-blue"
            onClick={closethismodal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CongratsModal;
