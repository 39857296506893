import React, { useEffect, useState } from "react";
import locationlogo from "../images/location.svg";
import phonelogo from "../images/phone.svg";
import gmailLogo from "../images/gmail.svg";
import lightLogo from "../images/lightLogo.svg";
import offerbackgraundbanner from "../images/ContactusBanner.png";

import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { ContactToUs } from "../ReduxToolkit/Slices/Auth/Auth.action";
const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let dispatch = useDispatch();
  let [authload, setauthload] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required!"),
      email: Yup.string().email("Invalid email address").required("Required"),
      subject: Yup.string().required("Subject is required!"),
      description: Yup.string().required("Description is required!"),
    }),
    onSubmit: (values, { resetForm }) => {
      let ContactUsData = new FormData();
      ContactUsData.append("name", values?.name);
      ContactUsData.append("email", values?.email);
      ContactUsData.append("subject", values?.subject);
      ContactUsData.append("description", values?.description);
      setauthload(true);
      dispatch(ContactToUs(ContactUsData))
        .unwrap()
        .then((result) => {
          if (result?.s == 1) {
            setauthload(false);
            toast(
              `Thank you for reaching out to us! We have recorded your response and will get back to you shortly`,
              {
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                  textAlign: "center",
                },
              }
            );
            resetForm();
          } else {
            setauthload(false);
            toast(result?.m, {
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          }
        });
    },
  });

  return (
    <div className="bg-[#FAFAFA]">
      <div className="w-full relative mx-auto ">
        <img
          className=" object-cover w-full h-[40vh] md:h-[60vh]"
          src={offerbackgraundbanner}
        />
        <div className="absolute top-0 m-auto w-full  h-full flex">
          <div className="  text-zinc-50 w-full  md:1/2 flex flex-col justify-center align-middle text-center m-auto ">
            <h2 className="font-extrabold text-cxl md:text-c_xl  ">
              Contact us
            </h2>
            {/* <p className="text-csm  md:text-c_sm  w-[95%] md:w-[50%] lg:w-[30%]  text-center m-auto">
              It is a long established fact that a reader will be distracted by
              the readable content of a.
            </p> */}
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="w-[90%] max-w-[1500px] mx-auto py-14">
          <div className="shadow-[0_1px_18px_0_rgba(0,0,0,0.04)] flex flex-col md:flex-row gap-4 justify-between bg-white p-4 md:p-6 lg:p-14 rounded-3xl">
            {/* -------- Address Section -------- */}
            <div className="relative bg-[rgba(254,241,248,0.5)] flex w-[100%] lg:w-[50%] rounded-3xl flex-col gap-2 md:gap-4 p-4 md:p-8 ">
              <p className="text-c_md text-primary-blue"> Address</p>
              <a
                className="cursor-pointer relative transition-all duration-300 hover:underline decoration-transparent hover:decoration-dark-blue"
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  "Auckland, New Zealand"
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex gap-2 items-center">
                  <img
                    src={locationlogo}
                    alt="partner_Logo"
                    className="w-[35px]  h-[35px]  "
                  />
                  <p className="text-csm lg:text-c_sm text-dark-blue">
                    Auckland, New Zealand
                  </p>
                </div>
              </a>

              <div className="flex gap-2 lg:gap-6 flex-col md:flex-row ">
                <a
                  className="cursor-pointer relative transition-all duration-300 hover:underline decoration-transparent hover:decoration-dark-blue"
                  href={`tel:+64 0204667283`}
                >
                  <div className="flex gap-2 items-center">
                    <img
                      src={phonelogo}
                      alt="partner_Logo"
                      className="w-[35px]  h-[35px]  "
                    />
                    <p className="text-csm lg:text-c_sm text-dark-blue">
                      +64 0204667283
                    </p>
                  </div>
                </a>
              </div>
              <a
                className="cursor-pointer relative transition-all duration-300 hover:underline decoration-transparent hover:decoration-dark-blue"
                href={`mailto:contact@saveon.co.nz">
              }`}
              >
                <div className="flex gap-2 items-center">
                  <img
                    src={gmailLogo}
                    alt="partner_Logo"
                    className="w-[35px]  h-[35px]  "
                  />
                  <p className="text-csm lg:text-c_sm text-dark-blue">
                    contact@saveon.co.nz
                  </p>
                </div>
              </a>
              <img
                src={lightLogo}
                className="w-[200px] lg:w-[250px] absolute right-0 bottom-0"
                alt=""
              />
            </div>
            {/* -------- Form Section -------- */}

            <form
              className="w-full md:w-[90%] lg:w-[45%]"
              onSubmit={formik.handleSubmit}
            >
              <div className="w-full  ">
                <h3 className="text-primary-blue text-c_lg">Get In touch</h3>

                {/* -------- Name Field -------- */}

                <div className="mt-6 relative">
                  <label
                    htmlFor="number"
                    className="text-left text-dark-text font-normal text-lg mb-2"
                  >
                    Name
                  </label>
                  <input
                    className="border  border-desc-text focus:border-primary-blue rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{
                      background: "rgba(254,241,248,1)",
                      color: "rgba(209, 5, 87, 1)",
                    }}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>

                <div className="mt-6 relative">
                  <label
                    htmlFor="number"
                    className="text-left text-dark-text font-normal text-lg mb-2"
                  >
                    Email
                  </label>
                  <input
                    className="border  border-desc-text focus:border-primary-blue rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Enter Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{
                      background: "rgba(254,241,248,1)",
                      color: "rgba(209, 5, 87, 1)",
                    }}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>

                {/* -------- Subject Field -------- */}

                <div className="mt-6 relative">
                  <label
                    htmlFor="number"
                    className="text-left text-dark-text font-normal text-lg mb-2"
                  >
                    Subject
                  </label>
                  <input
                    className="border  border-desc-text focus:border-primary-blue rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
                    type="text"
                    name="subject"
                    id="subject"
                    placeholder="Enter Subject"
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{
                      background: "rgba(254,241,248,1)",
                      color: "rgba(209, 5, 87, 1)",
                    }}
                  />
                  {formik.touched.subject && formik.errors.subject ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.subject}
                    </div>
                  ) : null}
                </div>

                {/* -------- Description Field -------- */}

                <div className="mt-6 relative">
                  <label
                    htmlFor="number"
                    className="text-left text-dark-text font-normal text-lg mb-2"
                  >
                    Description
                  </label>
                  <textarea
                    className="border  border-desc-text focus:border-primary-blue rounded-[10px] px-4 pt-4 pb-24 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
                    type="text"
                    name="description"
                    id="description"
                    placeholder="Enter Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{
                      background: "rgba(254,241,248,1)",
                      color: "rgba(209, 5, 87, 1)",
                    }}
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.description}
                    </div>
                  ) : null}
                </div>

                {/* -------- Submit Button -------- */}

                <button
                  disabled={authload}
                  className="bg-primary-blue py-2 px-6 text-[#FFF] rounded-lg"
                  type="submit"
                >
                  {authload ? "Loading..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
