import React, { useEffect, useState, useRef } from "react";
import offerbackgraundbanner from "../images/GiveawayBanner.png";
import { useDispatch, useSelector } from "react-redux";
import { Getgiveaways } from "../ReduxToolkit/Slices/Deals/Deals.action";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import toast from "react-hot-toast";
import LoginModal from "../components/modal/LoginModal";
import SignupModal from "../components/modal/SignupModal";
import ForgotPassword from "../components/modal/ForgotPassword";
import {
  GetsubscriptionPlans,
  VerifiySubscription,
} from "../ReduxToolkit/Slices/Subscription/Subscription.action";
import { GetUserProfileDetails } from "../ReduxToolkit/Slices/Auth/Auth.action";
import { useLocation, useNavigate } from "react-router-dom";
import CongratsModal from "../components/modal/CongratsModal";
import SubscriptionPlanCard from "../components/SubscriptionPlanCard";
import CancelledModal from "../components/modal/CancelledModal";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import { BaseUrl } from "../ReduxToolkit/Apis";
import Countdown from "react-countdown";

const Giveaways = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [count, setcount] = useState(0); // Offset for pagination

  let logindata = JSON.parse(localStorage.getItem("SaveOn_Web_Cread"));

  // -------- Is Check Login -------------
  const [ShowCancelledmodal, setShowCancelledmodal] = useState(false);

  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const openSignupModal = () => setIsSignupOpen(true);
  const closeSignupModal = () => setIsSignupOpen(false);

  const openLoginModal = () => setIsLoginOpen(true);
  const closeLoginModal = () => setIsLoginOpen(false);

  const openForgotPassModal = () => setIsForgotPasswordOpen(true);
  const closeForgotPassModal = () => setIsForgotPasswordOpen(false);

  const switchToForgotPass = () => {
    closeLoginModal();
    openForgotPassModal();
  };
  const switchToLogin = () => {
    closeSignupModal();
    closeForgotPassModal();
    openLoginModal();
  };

  const switchToSignup = () => {
    closeLoginModal();
    openSignupModal();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [count]);
  useEffect(() => {
    dispatch(Getgiveaways(count));
  }, [count]);
  useEffect(() => {
    dispatch(GetsubscriptionPlans());
    dispatch(GetUserProfileDetails(logindata?.id));
  }, [dispatch, navigate]);
  const Deals = useSelector((store) => store?.Deals);
  let Subscription = useSelector((store) => store?.Subscription);
  let AuthCheck = useSelector((store) => store?.Auth);

  const handleNext = () => {
    if (count + itemsPerPage < totalItems) {
      setcount((prevCount) => prevCount + itemsPerPage);
    }
  };

  const handlePrev = () => {
    setcount((prevCount) => (prevCount > 0 ? prevCount - itemsPerPage : 0));
  };
  const itemsPerPage = 10; // Number of items per page
  const totalItems = Deals?.giveaways?.count || 0; // Total count of items

  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const location = useLocation();
  let [verifiyplandata, setverifiyplandata] = useState(null);
  let [Verifiysubloader, setVerifiysubloader] = useState(false);
  useEffect(() => {
    const callAPI = async () => {
      try {
        const urlParams = new URLSearchParams(location.search);
        const token = urlParams.get("token");
        const subscription_id = urlParams.get("subscription_id");
        const type = urlParams.get("type");
        if (token) {
          setVerifiysubloader(true);
          let SubscriptionData = new FormData();
          SubscriptionData?.append("id", subscription_id);
          SubscriptionData?.append("type", type);
          await dispatch(VerifiySubscription(SubscriptionData))
            .unwrap()
            .then((result) => {
              if (result?.r?.status === "ACTIVE") {
                setVerifiysubloader(false);
                setShowCongratsModal(true);
                setverifiyplandata(result?.r);
                urlParams.delete("token");
                urlParams.delete("type");
                urlParams.delete("subscription_id");
                urlParams.delete("ba_token");
                urlParams.delete("PayerID");
                navigate({ search: urlParams.toString() }, { replace: true });
                dispatch(Getgiveaways(count));
                dispatch(GetsubscriptionPlans());
                dispatch(GetUserProfileDetails(logindata?.id));
              } else {
                setVerifiysubloader(false);
                setShowCancelledmodal(true);
                urlParams.delete("token");
                urlParams.delete("type");
                urlParams.delete("subscription_id");
                urlParams.delete("ba_token");
                urlParams.delete("PayerID");
                urlParams.delete("is_cancel");
                navigate({ search: urlParams.toString() }, { replace: true });
                dispatch(Getgiveaways(count));
                dispatch(GetsubscriptionPlans());
                dispatch(GetUserProfileDetails(logindata?.id));
              }
            });
        }
      } catch (error) {
        console.error("Error calling API:", error);
      }
    };
    callAPI();
  }, [location.search, dispatch, navigate]);

  const subscriptionSectionRef = useRef(null);
  const CheckIsLogin = () => {
    const isLoggedIn = localStorage.getItem("SaveOn_Web_Cread");
    if (isLoggedIn) {
      if (
        AuthCheck?.UserDetailsByid?.is_subscribe !== 4 &&
        AuthCheck?.UserDetailsByid?.is_subscribe !== 5
      ) {
        toast(" To enter in Giveaway, please subscribe ! ", {
          icon: "❌",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        subscriptionSectionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    } else {
      openLoginModal();
    }
  };

  let redirectToResult = (data) => {
    window.open(data?.url, "_blank");
  };

  return (
    <div className="bg-[#FAFAFA]">
      {Verifiysubloader && (
        <div className="fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center  bg-black bg-opacity-50">
          <span className="loader"></span>
        </div>
      )}
      <div className="w-full relative m-auto ">
        <LazyLoadImage
          effect="blur"
          className=" w-full h-[40vh] md:h-[60vh]"
          src={offerbackgraundbanner}
          alt="giveaway"
          height={"100%"}
          width={"100%"}
        />
        <div className="absolute top-0 m-auto w-full h-full flex">
          <div className="text-zinc-50 w-full md:1/2 flex flex-col justify-center align-middle text-center m-auto ">
            <h2 className="font-extrabold text-cxl md:text-c_xl">Giveaways</h2>
          </div>
        </div>
      </div>

      {Deals?.giveaways?.r?.length <= 0 ? (
        <div className="p-4   text-center text-[25px] text-primary-blue">
          No Giveaways Available !
        </div>
      ) : (
        <div className="p-1 flex flex-col gap-4   text-center text-[25px] text-primary-blue">
          {Deals?.giveaways?.r?.map((data, index) => {
            const currentDate = Date.now();
            const startDate = new Date(data?.start_date).getTime();
            const endDate = new Date(data?.end_date).getTime();
            const oneWeekBeforeStartDate = startDate - 7 * 24 * 60 * 60 * 1000; // 1 week before start date
            const isSubscribed = AuthCheck?.UserDetailsByid?.is_subscribe === 1;
            const isMoreThanAWeekAway = currentDate < oneWeekBeforeStartDate;
            const isWithinOneWeek =
              currentDate >= oneWeekBeforeStartDate && currentDate < startDate;
            const isLive = currentDate >= startDate && currentDate < endDate;
            const isEnded = currentDate >= endDate;
            return (
              <div
                key={index}
                className="flex   flex-col md:flex-row items-center gap-4 lg:gap-14 py-8 md:w-[90%] w-[95%] m-auto  border-2 hover:bg-light-blue hover:border-primary-blue hover:border-2 p-2 rounded-lg transition-all duration-300"
              >
                <div className="w-full md:w-[450px] lg:w-[550px] xl:w-[600px] p-2 md:p-4 border-primary-blue rounded-lg">
                  <LazyLoadImage
                    effect="blur"
                    src={data?.image !== 0 ? `${BaseUrl}${data?.image}` : ""}
                    alt="giveaway"
                    height={"100%"}
                    width={"100%"}
                  />
                </div>
                <div className="flex flex-col justify-start items-start gap-4 w-[95%] md:w-[80%]">
                  <h3
                    className="text-2xl text-start md:text-c_lg xl:text-cxl text-dark-text"
                    style={{}}
                  >
                    {data?.title}
                  </h3>
                  <p
                    style={{}}
                    className="text-desc-text text-start w-full   text-sm break-words"
                  >
                    {data?.subtitle}
                  </p>

                  {isMoreThanAWeekAway && (
                    <button
                      disabled
                      className="bg-dark-blue text-[20px] md:text-[25px] lg:text-[28px] py-1 px-4  text-nowrap rounded-lg w-fit text-white transition-all duration-300 ease-in-out hover:bg-primary-blue"
                    >
                      Coming Soon ⏳
                    </button>
                  )}
                  {isWithinOneWeek && (
                    <>
                      <Countdown
                        date={startDate}
                        renderer={({ days, hours, minutes, seconds }) => (
                          <div className="flex justify-center gap-2">
                            <div className="border-primary-blue text-sm md:text-base  flex flex-col items-center rounded-lg w-[60px] sm:w-[70px] border p-2 text-primary-blue">
                              <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold">
                                {days}
                              </span>
                              Days
                            </div>
                            <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold mt-4">
                              :
                            </span>
                            <div className="border-primary-blue text-sm md:text-base   flex flex-col items-center rounded-lg w-[60px] sm:w-[70px] border p-2 text-primary-blue">
                              <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold">
                                {hours}
                              </span>{" "}
                              Hours
                            </div>
                            <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold mt-4">
                              :
                            </span>
                            <div className="border-primary-blue text-sm md:text-base   flex flex-col items-center rounded-lg w-[60px] sm:w-[70px] border p-2 text-primary-blue">
                              <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold">
                                {minutes}
                              </span>
                              Minutes
                            </div>
                            <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold mt-4">
                              :
                            </span>
                            <div className="border-primary-blue text-sm md:text-base  flex flex-col items-center rounded-lg w-[60px] sm:w-[70px] border p-2 text-primary-blue">
                              <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold">
                                {seconds}
                              </span>{" "}
                              Seconds
                            </div>
                          </div>
                        )}
                      />
                      <h1 className="text-primary-blue text-center font-semibold">
                        Giveaway Starts In 🕑
                      </h1>
                    </>
                  )}
                  {isLive && (
                    <>
                      <Countdown
                        date={endDate}
                        renderer={({ days, hours, minutes, seconds }) => (
                          <div className="flex justify-center gap-2">
                            <div className="border-primary-blue text-sm md:text-base bg-light-blue flex flex-col items-center rounded-lg w-[60px] sm:w-[70px] border p-2 text-primary-blue">
                              <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold">
                                {days}
                              </span>
                              Days
                            </div>
                            <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold mt-4">
                              :
                            </span>
                            <div className="border-primary-blue text-sm md:text-base bg-light-blue flex flex-col items-center rounded-lg w-[60px] sm:w-[70px] border p-2 text-primary-blue">
                              <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold">
                                {hours}
                              </span>{" "}
                              Hours
                            </div>
                            <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold mt-4">
                              :
                            </span>
                            <div className="border-primary-blue text-sm md:text-base bg-light-blue flex flex-col items-center rounded-lg w-[60px] sm:w-[70px] border p-2 text-primary-blue">
                              <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold">
                                {minutes}
                              </span>{" "}
                              Minutes
                            </div>
                            <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold mt-4">
                              :
                            </span>
                            <div className="border-primary-blue text-sm md:text-base bg-light-blue flex flex-col items-center rounded-lg w-[60px] sm:w-[70px] border p-2 text-primary-blue">
                              <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold">
                                {seconds}
                              </span>{" "}
                              Seconds
                            </div>
                          </div>
                        )}
                      />
                      <h1 className="text-primary-blue font-semibold">
                        Giveaway is Live 🎉
                      </h1>
                      <div className="flex gap-4 items-center ">
                        <button
                          disabled={isSubscribed}
                          onClick={CheckIsLogin}
                          className="bg-dark-blue text-[20px] md:text-[25px] lg:text-[28px] py-1 px-4  text-nowrap rounded-lg w-fit text-white transition-all duration-300 ease-in-out hover:bg-primary-blue"
                        >
                          {isSubscribed ? "You're In" : "Enter Now"}
                        </button>
                        {isSubscribed ? (
                          <button
                            onClick={() => redirectToResult(data)}
                            className="bg-dark-blue text-[20px] md:text-[25px] lg:text-[28px] py-1 px-4  text-nowrap rounded-lg w-fit text-white transition-all duration-300 ease-in-out hover:bg-primary-blue"
                          >
                            View Details
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  )}
                  {isEnded && (
                    <div className="flex gap-4 justify-center items-center">
                      <div className="  text-[20px] md:text-[25px] lg:text-[28px] py-1 px-4   w-fit text-gray-400">
                        Ended
                      </div>
                      <button
                        onClick={() => redirectToResult(data)}
                        className="bg-gray-400 text-[20px] md:text-[25px] lg:text-[28px] py-1 px-4 rounded-lg w-fit text-white"
                      >
                        View Result
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {totalItems > itemsPerPage && (
        <div className="flex justify-end items-center gap-2 w-[90%] max-w-[1500px] mx-auto py-8">
          <button
            onClick={handlePrev}
            disabled={count < itemsPerPage}
            className={` p-1 cursor-pointer border hover:border-black text-white  bg-primary-blue border-primary-blue rounded-full   ${
              count < itemsPerPage ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <GrFormPreviousLink />
          </button>
          <p>
            Page: {Math.ceil(count / itemsPerPage) + 1} of{" "}
            {Math.ceil(totalItems / itemsPerPage)}
          </p>
          <button
            onClick={handleNext}
            disabled={count + itemsPerPage >= totalItems}
            className={` p-1 cursor-pointer border hover:border-black  text-white  bg-primary-blue border-primary-blue rounded-full   ${
              count + itemsPerPage >= totalItems
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
          >
            <GrFormNextLink />
          </button>
        </div>
      )}
      <div className="w-[90%] flex flex-col gap-4 max-w-[1500px] mx-auto">
        <div className="pt-8 pb-14" ref={subscriptionSectionRef}>
          <h2 className="text-[#0E2F43] text-center text-cxl mb-4">
            Subscription
          </h2>
          <div className="mt-14 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 lg:gap-14 w-full mx-auto    ">
            {logindata?.role == 3
              ? Subscription?.planDetails
                  ?.filter((el) => el?.type == "2" || el?.type == "3")
                  .map((el, index) => (
                    <SubscriptionPlanCard
                      key={index} // Always include a key when mapping elements
                      planData={el}
                    />
                  ))
              : Subscription?.planDetails
                  ?.filter((el) => el?.type == "2")
                  .map((el, index) => (
                    <SubscriptionPlanCard
                      key={index} // Always include a key when mapping elements
                      planData={el}
                    />
                  ))}
          </div>
        </div>
      </div>
      {isLoginOpen && (
        <LoginModal
          closeLoginModal={closeLoginModal}
          switchToSignup={switchToSignup}
          switchToForgotPass={switchToForgotPass}
        />
      )}
      {isSignupOpen && (
        <SignupModal
          closeSignupModal={closeSignupModal}
          switchToLogin={switchToLogin}
        />
      )}
      {isForgotPasswordOpen && (
        <ForgotPassword
          closeForgotPassModal={closeForgotPassModal}
          switchToForgotPass={switchToForgotPass}
        />
      )}
      <CongratsModal
        data={verifiyplandata && verifiyplandata}
        setShowCongratsModal={setShowCongratsModal}
        showCongratsModal={showCongratsModal}
      />
      <CancelledModal
        setShowCancelledmodal={setShowCancelledmodal}
        ShowCancelledmodal={ShowCancelledmodal}
      />
    </div>
  );
};

export default Giveaways;
