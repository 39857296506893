import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  PDFViewer,
  pdf,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import imagedummy from "../images/SaveonPdfLogo.png"
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
    marginTop: "80px",
  },
  couponCard: {
    width: "100%",
    border: "1 solid #e0e0e0",
    borderRadius: 8,
    overflow: "hidden",
  },
  contentContainer: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: "rgba(254, 229, 243, 1)",
  },
  imageSection: {
    width: "40%",
    height: 180,
    padding: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  infoSection: {
    width: "60%",
    padding: 16,
    alignItems: "start",
    justifyContent: "center",
    display: "flex",
    gap: "5px",
  },
  priceContainer: {
    flexDirection: "row",
    alignItems: "baseline",
    backgroundColor: "rgba(255,48,147,1)",
    padding: "12 24",
    borderRadius: 6,
    minWidth: "120px",
    maxWidth: "180px",
  },
  strikePrice: {
    fontSize: 14,
    color: "white",
    textDecoration: "line-through",
    marginLeft: 8,
  },
  actualPrice: {
    fontSize: 24,
    color: "white",
    fontWeight: "bold",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 8,
    textAlign: "start",
    color: "rgba(255,48,147,1)",
  },
  date: {
    fontSize: 10,
    color: "gray",
    marginTop: 8,
  },
  codeSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgba(255,48,147,1)",
    padding: "16 40",
    borderTop: "2 dashed white",
  },
  codeText: {
    color: "white",
    fontSize: 18,
    fontWeight: "bold",
  },
  codeValue: {
    backgroundColor: "rgba(255,48,147,1)",
    padding: 8,
    color: "white",
    border: "1px solid white",
  },
});

const GenerateCouponPdf = ({ data }) => {
  if (!data) return null;

  const handleDownload = async () => {
    const doc = (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.couponCard}>
            <View style={styles.contentContainer}>
              <View style={styles.imageSection}>
              <Image
                  style={styles.image}
                  src={
                    imagedummy
                  }
                />
              </View>
              <View style={styles.infoSection}>
                <View style={styles.priceContainer}>
                  <Text style={styles.actualPrice}>
                    {data?.offerData?.strike_amount}$
                  </Text>
                  <Text style={styles.strikePrice}>
                    {data?.offerData?.real_amount}$
                  </Text>
                </View>
                <Text style={styles.title}>{data?.offerData?.title}</Text>
                <Text style={styles.date}>
                  Exp:{" "}
                  {moment(data?.coupendata?.expiry_date)
                    .local()
                    .format("DD MMM YYYY") || "--"}
                </Text>
              </View>
            </View>
            <View style={styles.codeSection}>
              <Text style={styles.codeText}>Code</Text>
              <Text style={styles.codeValue}>{data?.coupendata?.code}</Text>
            </View>
          </View>
        </Page>
      </Document>
    );
    const fileName = data?.offerData?.title || "Untitled";
    const blob = await pdf(doc).toBlob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName; // Set the dynamic file name here
    link.click();
    URL.revokeObjectURL(link.href);
  };

  return (
    <div>
      <PDFViewer width="100%"  height={"430px"}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.couponCard}>
              <View style={styles.contentContainer}>
                <View style={styles.imageSection}>
                   <Image
                  style={styles.image}
                  src={
                    imagedummy
                  }
                />
                </View>
                <View style={styles.infoSection}>
                  <View style={styles.priceContainer}>
                    <Text style={styles.actualPrice}>
                      {data?.offerData?.strike_amount}$
                    </Text>
                    <Text style={styles.strikePrice}>
                      {data?.offerData?.real_amount}$
                    </Text>
                  </View>
                  <Text style={styles.title}>{data?.offerData?.title}</Text>
                  <Text style={styles.date}>
                    Exp:{" "}
                    {moment(data?.coupendata?.expiry_date)
                      .local()
                      .format("DD MMM YYYY") || "--"}
                  </Text>
                </View>
              </View>
              <View style={styles.codeSection}>
                <Text style={styles.codeText}>Code</Text>
                <Text style={styles.codeValue}>{data?.coupendata?.code}</Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
      <button onClick={handleDownload} style={{margin:"auto",marginTop:"20px",width:"150px",backgroundColor:"rgba(255,48,147,1)",color:"white",padding:"10px",borderRadius:"10px",    }}>
        Download PDF
      </button>
    </div>
  ); 
};

export default GenerateCouponPdf;  

 