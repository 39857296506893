import moment from "moment";
import React, { useEffect, useRef } from "react";
import { RxCrossCircled } from "react-icons/rx";

const ViewmoreModal = ({ showViewMoreModal, data }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        showViewMoreModal(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showViewMoreModal]);

  if (!showViewMoreModal) {
    return null; // Don't render the modal if the state is false
  }

  return (
    <div className="fixed z-[105] inset-0 bg-[rgba(54,0,17,0.84)] flex items-center justify-center">
      <div
        ref={modalRef}
        className="bg-white  rounded-xl relative w-[90%] max-w-[950px] tracking-wide"
      >
        <RxCrossCircled
          style={{
            fontSize: "30px",
            top: "-50px",
            right: "0",
            position: "absolute",
            color: "#fff",
            cursor: "pointer",
          }}
          onClick={showViewMoreModal}
        />
        <div className="relative overflow-x-auto no-scrollba  rounded-xl">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className=" text-dark-text capitalize bg-transparent ">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-6 text-base sm:text-lg font-medium text-nowrap whitespace-nowrap"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="px-6 py-6 text-base sm:text-lg font-medium text-nowrap whitespace-nowrap"
                >
                  Plan
                </th>
                <th
                  scope="col"
                  className="px-6 py-6 text-base sm:text-lg font-medium text-nowrap whitespace-nowrap"
                >
                  Start Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-6 text-base sm:text-lg font-medium text-nowrap whitespace-nowrap"
                >
                  End Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-6 text-base sm:text-lg font-medium text-nowrap whitespace-nowrap"
                >
                  Days
                </th>
                <th
                  scope="col"
                  className="px-6 py-6 text-base sm:text-lg font-medium text-nowrap whitespace-nowrap"
                >
                  Available Coupons
                </th>
              </tr>
            </thead>

            <tbody>
              <tr className="bg-[rgba(21,74,101,0.06)]">
                <th
                  scope="row"
                  className="px-6 py-3 text-lg sm:text-2xl font-medium text-dark-blue whitespace-nowrap"
                >
                  ${data?.amount}
                </th>
                <td className="px-6 py-3 text-base text-primary-blue font-medium whitespace-nowrap text-nowrap">
                  {data?.plan_name}
                </td>
                <td className="px-6 py-3 text-base text-primary-blue  font-medium whitespace-nowrap text-nowrap">
                  {moment(data?.start_date).local().format("MMMM Do YYYY") ||
                    "--"}
                </td>
                <td className="px-6 py-3 text-base text-dark-blue font-medium whitespace-nowrap text-nowrap">
                  {moment(data?.end_date).local().format("MMMM Do YYYY") ||
                    "--"}
                </td>
                <td className="px-6 py-3">
                  <p className="text-dark-blue text-base font-medium">
                    <span>{data?.days}</span> Days
                  </p>
                </td>
                <td className="px-6 py-3 text-center flex justify-center">
                  <div className="p-3 text-center  bg-light-blue text-base font-semibold rounded-xl w-fit text-primary-blue whitespace-nowrap text-nowrap">
                    {data?.payment_history?.token} Coupons
                  </div>
                </td>
              </tr>
              <tr className="bg-white">
                <td colSpan="6" className="px-6 py-3">
                  <span className="text-nowrap text-dark-text text-lg">
                    Transaction ID
                  </span>
                </td>
              </tr>
              <tr className="bg-[rgba(21,74,101,0.06)]">
                <td colSpan="6" className="px-6 py-6">
                  <span className="text-nowrap text-base font-semibold text-primary-blue">
                    {data?.payment_history?.transaction_id}
                  </span>
                </td>
              </tr>
              <tr className="bg-white">
                <td colSpan="6" className="px-6 py-3">
                  <span className="text-nowrap text-lg text-dark-text">
                    Description
                  </span>
                </td>
              </tr>
              <tr className="bg-light-blue">
                <td colSpan="6" className="px-6 py-6">
                  <ul className=" px-4 list-disc text-desc-text text-base ml-4 md:ml-0">
                    {data?.payment_history?.description
                      ?.split("\n")
                      ?.map((desc) => desc.trim())
                      ?.filter((desc) => desc) // Remove empty lines if any
                      ?.map((pl, index) => (
                        <div key={index} className="flex items-center gap-2">
                          <li className="text-c_sm">{pl}</li>
                        </div>
                      ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewmoreModal;
