import React from "react";
import userIcon from "../images/usericon.png";
import { BaseUrl } from "../ReduxToolkit/Apis";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import usericonplaceholder from "../images/usericon.png";
import dawonloadicon from "../images/Dawonloadcardicon.png";
const Card = ({ data }) => {
  return (
    <>
      <div className="group w-full shadow-[0_2px_8px_rgba(0,0,0,0.1)] cursor-pointer border-2 border-transparent hover:border-primary-blue transition-border duration-300 rounded-md">
        <div className="w-full relative ">
          <div className=" ribbon  absolute  text-dark-text z-20  top-5 left-0 md:text-[20px] lg:text-[16px] xl:text-[20px] overflow-hidden text-ellipsis whitespace-nowrap">
            {data?.labels}
          </div>
          <div className=" ">
            <LazyLoadImage
              placeholderSrc={usericonplaceholder}
              effect="blur"
             
              alt="category image"
              src={
                data?.images[0] !== 0
                  ? `${BaseUrl}${data?.images[0]?.image}`
                  : ""
              }
              className="  aspect-w-16   "
            />
          </div>
        </div>
        <div className="p-4 flex flex-col gap-1 ">
          <h1 className="text-dark-text text-[28px]  font-semibold  text-nowrap transition-all duration-300 overflow-hidden text-ellipsis whitespace-nowrap">
            {data?.title}
          </h1>
          {/* <div> */}
          
            <p className="text-desc-text text-c_sm overflow-hidden text-ellipsis whitespace-nowrap">
              {data?.subtitle ? data?.subtitle : "....."}
            </p>
            <p className="text-primary-blue text-c_md overflow-hidden text-ellipsis whitespace-nowrap">
              {data?.partner_name ? data?.partner_name : "....."}
            </p>
            {/* <p
              className="text-clamp  text-desc-text  "
              dangerouslySetInnerHTML={{
                __html: data?.partner_name ? data?.partner_name : ".....",
              }}
            /> */}
          {/* </div> */}
          <div className="flex justify-between">
            <div className="flex items-baseline gap-2">
              <span className="text-cx lg:text-c_x font-bold group-hover:primary-dark-blue   text-primary-blue transition-all duration-300">
                ${data?.strike_amount ? data?.strike_amount : "0"}
              </span>
              <span className="text-[#6E818D] text-[14px] line-through">
                ${data?.real_amount ? data?.real_amount : 0}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <img
                className="w-[30px] lg:w-[40px]"
                src={dawonloadicon}
                alt="user icon"
              />
              <span className="text-primary-blue font-semibold text-[14px] lg:text-[16px]">
                {data?.used_coupon_count ? data?.used_coupon_count : 0}{" "}
                Redeemed
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
